
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import SetPage from "./pages/SetPage";

function App() {
  return (
    <BrowserRouter>
      {/* <Routes>
        <Route index element={<LandingPage />} />
          <Route path='/cards' element={<SetPage/>}></Route>
      </Routes> */}
      <Routes>

        <Route index element={<div className="text-4xl text-primary mt-40 ml-40 ">Coming Soon</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
